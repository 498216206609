import React, { useEffect } from 'react';
import { XCircle, AlertTriangle } from 'lucide-react';

const ErrorMessage = ({ message, onClose }) => {
  useEffect(() => {
    if (message) {
      // Auto cerrar después de 3 segundos
      const timer = setTimeout(() => {
        if (onClose) onClose();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [message, onClose]);

  if (!message) return null;

  const handleClose = (e) => {
    e.stopPropagation();
    if (onClose) onClose();
  };

  return (
    <div 
      className="fixed inset-0 flex items-center justify-center bg-black/50 z-50"
      onClick={handleClose}
    >
      <div 
        className="bg-white rounded-lg shadow-xl max-w-md w-full mx-4 p-0"
        onClick={e => e.stopPropagation()}
      >
        <div className="bg-red-500 p-4 rounded-t-lg flex items-center justify-between">
          <div className="flex items-center gap-2 text-white">
            <AlertTriangle className="w-5 h-5" />
            <span className="font-semibold">Error</span>
          </div>
          <button 
            onClick={handleClose}
            className="text-white hover:opacity-80"
          >
            <XCircle className="w-5 h-5" />
          </button>
        </div>
        
        <div className="p-4">
          <p className="text-gray-800">{message}</p>
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;