import React, { useState, useEffect } from 'react';
import '../../assets/styles.css';
import { motion, AnimatePresence } from 'framer-motion';
import {
  HomeIcon,
  SparklesIcon,
  UserCircleIcon,
  CalendarIcon,
  ClipboardDocumentListIcon,
  ClockIcon,
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  XMarkIcon,
  UserIcon, // Añadido para el botón de perfil
} from '@heroicons/react/24/outline';

const Sidebar = ({
  firstName, 
  lastName,
  onLogout,
  onShowHistory,
  onShowPlanCreation,
  isHistoryActive,
  currentSection,
}) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1024);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  const closeMobileMenu = () => setIsMobileMenuOpen(false);

  const marketingPlanSections = [
    { name: 'introduccion', label: 'Introducción', internalName: 'Entrevista', icon: <HomeIcon className="w-5 h-5" /> },
    { name: 'intereses', label: 'Intereses', internalName: 'Intereses', icon: <SparklesIcon className="w-5 h-5" /> },
    { name: 'testPersonalidad', label: 'Test de Personalidad', internalName: 'Test de Personalidad', icon: <UserCircleIcon className="w-5 h-5" /> },
    { name: 'ultimosPosteos', label: 'Últimos Posteos', internalName: 'Posteos Anteriores', icon: <ClipboardDocumentListIcon className="w-5 h-5" /> },
    { name: 'preferenciasMarketing', label: 'Preferencias de Marketing', internalName: 'Preferencias de Marketing', icon: <ClockIcon className="w-5 h-5" /> },
  ];

  return (
    <>
     {/* Header móvil */}
{isSmallScreen && (
  <div className="fixed top-0 left-0 right-0 bg-gray-900/95 backdrop-blur-sm border-b border-gray-800 px-4 py-3 flex items-center justify-between z-[999]">
    <h2 className="text-lg font-semibold text-white truncate">
      {firstName} {lastName}
    </h2>
    <button 
      onClick={toggleMobileMenu}
      className="p-2 hover:bg-gray-800 rounded-lg transition-colors"
    >
      {isMobileMenuOpen ? (
        <XMarkIcon className="w-6 h-6 text-gray-200" />
      ) : (
        <Bars3Icon className="w-6 h-6 text-gray-200" />
      )}
    </button>
  </div>
)}

<AnimatePresence>
  {isSmallScreen && isMobileMenuOpen && (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/60 backdrop-blur-sm z-[1000]"
      onClick={closeMobileMenu}
    />
  )}
</AnimatePresence>

      <motion.aside
  className={`
    ${isSmallScreen ? 'fixed' : 'sticky'} 
    top-0 left-0 
    bg-gradient-to-b from-gray-900 to-gray-800
    border-r border-gray-700/50 shadow-xl
    flex flex-col
    w-72 
    ${isSmallScreen ? 'h-[100dvh]' : 'h-screen'}
    ${isSmallScreen ? 'z-[1001]' : 'z-0'}
    ${isSmallScreen ? 'transform transition-transform duration-300' : ''}
    ${isSmallScreen && !isMobileMenuOpen ? '-translate-x-full' : 'translate-x-0'}
  `}
  initial={false}
>
        <div className="flex flex-col h-full">
  {/* Logo Section with Gradient Border */}
  <div className="p-6 flex flex-col items-center bg-gradient-to-b from-gray-800/50 to-transparent border-b border-gray-700/30">
    <img 
      src="/personal-marka.png" 
      alt="Logo Personal Marka" 
      className="w-36 h-auto drop-shadow-lg"
    />
    <span className="text-emerald-400 text-xs font-semibold mt-1">beta</span>
  </div>

          {/* User Info Section */}
          <div className="px-4 py-3 mb-2">
            <h3 className="text-gray-200 font-medium text-sm">Hola!</h3>
            <p className="text-white font-semibold">{firstName} {lastName}</p>
          </div>

          {/* Main Navigation */}
          <nav className="flex-1 px-3 pb-4 overflow-y-auto space-y-1">
            {marketingPlanSections.map((section) => (
              <motion.button
                key={section.name}
                onClick={() => {
                  onShowPlanCreation(section.name);
                  closeMobileMenu();
                }}
                className={`
                  w-full flex items-center gap-3 px-4 py-3
                  text-sm font-medium text-gray-300
                  rounded-lg transition-all duration-200
                  hover:bg-white/10 hover:text-white
                  ${currentSection === section.internalName ? 'bg-white/15 text-white shadow-lg' : ''}
                `}
                whileHover={{ x: 4 }}
                whileTap={{ scale: 0.98 }}
              >
                <span className="text-gray-400 group-hover:text-white transition-colors">
                  {section.icon}
                </span>
                <span>{section.label}</span>
              </motion.button>
            ))}

            <motion.button
              onClick={() => {
                onShowHistory();
                closeMobileMenu();
              }}
              className={`
                w-full flex items-center gap-3 px-4 py-3
                text-sm font-medium text-gray-300
                rounded-lg transition-all duration-200
                hover:bg-white/10 hover:text-white
                ${isHistoryActive ? 'bg-white/15 text-white shadow-lg' : ''}
              `}
              whileHover={{ x: 4 }}
              whileTap={{ scale: 0.98 }}
            >
              <ClipboardDocumentListIcon className="w-5 h-5 text-gray-400" />
              <span>Historial</span>
            </motion.button>

            <motion.button
              onClick={() => {
                onShowPlanCreation('verEditarCalendario');
                closeMobileMenu();
              }}
              className="w-full flex items-center gap-3 px-4 py-3 text-sm font-medium text-gray-300 rounded-lg transition-all duration-200 hover:bg-white/10 hover:text-white"
              whileHover={{ x: 4 }}
              whileTap={{ scale: 0.98 }}
            >
              <CalendarIcon className="w-5 h-5 text-gray-400" />
              <span>Ver/Crear Calendario</span>
            </motion.button>
          </nav>

          {/* Logout Button with Gradient Border */}
          <div className="mt-auto px-3 pb-4 pt-2 border-t border-gray-700/30">
          <motion.button
              onClick={() => {
                onShowPlanCreation('perfil');
                closeMobileMenu();
              }}
              className="w-full flex items-center gap-3 px-4 py-3 text-sm font-medium text-gray-300 rounded-lg transition-all duration-200 hover:bg-white/10 hover:text-white"
              whileHover={{ x: 4 }}
              whileTap={{ scale: 0.98 }}
            >
              <UserIcon className="w-5 h-5 text-gray-400" />
              <span>Perfil</span>
            </motion.button>
            <motion.button
              onClick={() => {
                onLogout();
                closeMobileMenu();
              }}
              className="w-full flex items-center gap-3 px-4 py-3 text-sm font-medium text-red-400 rounded-lg transition-all duration-200 hover:bg-red-500/10 hover:text-red-300"
              whileHover={{ x: 4 }}
              whileTap={{ scale: 0.98 }}
            >
              <ArrowLeftOnRectangleIcon className="w-5 h-5" />
              <span>Cerrar Sesión</span>
            </motion.button>
          </div>
        </div>
      </motion.aside>
    </>
  );
};

export default Sidebar;