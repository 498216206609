import React from 'react';

const DotLoader = () => {
  return (
    <div 
      className="flex justify-center items-center h-screen"
      style={{
        background: 'linear-gradient(169deg, #000000 0%, #0A403C 100%)'
      }}
    >
      <div className="flex space-x-2">
        {[...Array(3)].map((_, i) => (
          <div
            key={i}
            className="w-4 h-4 bg-white rounded-full animate-bounce"
            style={{
              animationDelay: `${i * 0.2}s`,
              animationDuration: '0.8s'
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default DotLoader;