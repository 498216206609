import React, { useState } from 'react';

const PasswordResetForm = ({ onResetPassword, email }) => {
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert("Las contraseñas no coinciden");
      return;
    }
    onResetPassword(email, code, newPassword);
  };

  return (
    <div className="min-h-screen flex">
      {/* Left side */}
      <div 
        className="hidden md:flex md:w-1/2 relative items-center justify-center"
        style={{
          background: 'linear-gradient(169deg, #000000 0%, #0A403C 100%)'
        }}
      >
        <div className="w-[420px] px-8 py-12">
          {/* Logo */}
          <img 
            src="https://vos.personalmarka.com/personal-marka.png" 
            alt="Personal Marka" 
            className="h-12 mb-12"
          />
          
          <div className="space-y-12">
            <div>
              <h1 className="text-5xl font-bold text-white leading-tight">
                Crear nueva<br />
                contraseña
              </h1>
              
              <p className="text-[#76A695] text-base mt-2">
                Te hemos enviado un código de verificación<br />
                a tu correo electrónico
              </p>
            </div>

            <div className="space-y-8">
              <div>
                <h3 className="text-white text-xl font-medium">
                  Verifica tu código
                </h3>
                <p className="text-[#76A695] text-base mt-0.5">
                  Ingresa el código de 6 dígitos que<br />
                  enviamos a tu correo
                </p>
              </div>
              
              <div>
                <h3 className="text-white text-xl font-medium">
                  Crea una contraseña segura
                </h3>
                <p className="text-[#76A695] text-base mt-0.5">
                  Usa una combinación de letras,<br />
                  números y símbolos
                </p>
              </div>
              
              <div>
                <h3 className="text-white text-xl font-medium">
                  Confirma los cambios
                </h3>
                <p className="text-[#76A695] text-base mt-0.5">
                  Verifica que todo esté correcto<br />
                  antes de guardar
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Right side - Form */}
      <div className="w-full md:w-1/2 flex items-center justify-center bg-white">
        <div className="w-[420px] px-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-2">
            Crear nueva contraseña
          </h2>
          <p className="text-gray-700 text-base mb-8">
            Hemos enviado un código a <span className="font-medium">{email}</span>. Ingrésalo junto con tu nueva contraseña.
          </p>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="code" className="block text-base font-medium text-gray-800 mb-1">
                Código de verificación:
              </label>
              <input
                type="text"
                id="code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded text-gray-900"
                placeholder="123456"
              />
            </div>

            <div>
              <label htmlFor="new-password" className="block text-base font-medium text-gray-800 mb-1">
                Nueva contraseña:
              </label>
              <input
                type="password"
                id="new-password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded text-gray-900"
                placeholder="••••••••"
              />
            </div>

            <div>
              <label htmlFor="confirm-password" className="block text-base font-medium text-gray-800 mb-1">
                Confirmar contraseña:
              </label>
              <input
                type="password"
                id="confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded text-gray-900"
                placeholder="••••••••"
              />
            </div>

            <button
              type="submit"
              className="w-full py-2.5 bg-black text-white rounded hover:bg-gray-900 transition-colors"
            >
              Restablecer contraseña
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetForm;