import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { 
  UserIcon, 
  LockClosedIcon, 
  CreditCardIcon, 
  CheckIcon,
  BriefcaseIcon,
  ClockIcon,
  ShieldCheckIcon,
  SparklesIcon,
  StarIcon
} from '@heroicons/react/24/outline';
import CancelSubscriptionDialog from './CancelSubscriptionDialog';

const SUBSCRIPTION_PLANS = {
  foco: {
    id: 'foco',
    name: 'Plan Foco',
    price: 5.99,
    color: 'from-blue-500 to-blue-600',
    features: [
      'Estrategia de contenido básica',
      'Publicaciones semanales',
      'Análisis mensual',
      'Soporte por email'
    ],
    icon: <SparklesIcon className="w-6 h-6" />
  },
  potencia: {
    id: 'potencia',
    name: 'Plan Potencia',
    price: 10.99,
    color: 'from-purple-500 to-purple-600',
    features: [
      'Todo lo del Plan Foco',
      'Estrategia personalizada',
      'Publicaciones diarias',
      'Análisis semanal',
      'Soporte prioritario'
    ],
    icon: <StarIcon className="w-6 h-6" />,
    popular: true
  },
  brillante: {
    id: 'brillante',
    name: 'Plan Brillante',
    price: 49.99,
    color: 'from-amber-500 to-amber-600',
    features: [
      'Todo lo del Plan Potencia',
      'Estrategia premium',
      'Contenido optimizado SEO',
      'Análisis en tiempo real',
      'Soporte 24/7',
      'Consultoría personalizada'
    ],
    icon: <ShieldCheckIcon className="w-6 h-6" />
  }
};

const TabButton = ({ active, icon, label, onClick }) => (
  <motion.button
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    onClick={onClick}
    className={`
      flex items-center gap-3 px-6 py-4 rounded-xl transition-all duration-300
      ${active 
        ? 'bg-gradient-to-r from-blue-600 to-blue-700 text-white shadow-lg shadow-blue-900/20' 
        : 'bg-gray-800/50 text-gray-300 hover:bg-gray-700/50'}
    `}
  >
    {icon}
    <span className="font-medium">{label}</span>
  </motion.button>
);
const PlanCard = ({ plan, isActive, onSubscribe, loading, subscription, setShowCancelDialog }) => {
  // Verificar si la suscripción está cancelada usando subscription.cancelled
  const isCancelled = subscription?.cancelled || false;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
      className={`
        relative p-6 rounded-xl backdrop-blur-sm border-2 transition-all duration-300
        ${isActive 
          ? 'border-blue-500 bg-blue-900/20' 
          : 'border-gray-700 bg-gray-800/50 hover:border-gray-600'}
        ${plan.popular ? 'md:scale-105' : ''}
      `}
    >
      {plan.popular && (
        <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
          <span className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-4 py-1 rounded-full text-sm font-medium shadow-lg">
            Más Popular
          </span>
        </div>
      )}

      <div className="text-center mb-6">
        <div className={`w-12 h-12 mx-auto mb-4 rounded-full bg-gradient-to-r ${plan.color} flex items-center justify-center text-white`}>
          {plan.icon}
        </div>
        <h4 className="text-xl font-bold text-white mb-2">{plan.name}</h4>
        <div className="text-3xl font-bold text-white mb-1">
          ${plan.price}
          <span className="text-sm font-normal text-gray-400">/mes</span>
        </div>
        <p className="text-sm text-gray-400">Facturación trimestral</p>
      </div>

      <ul className="space-y-3 mb-6">
        {plan.features.map((feature, index) => (
          <li key={index} className="flex items-start gap-2 text-gray-300">
            <CheckIcon className="w-5 h-5 text-green-400 flex-shrink-0 mt-0.5" />
            <span>{feature}</span>
          </li>
        ))}
      </ul>

      {isActive && (
        <div>
          {isCancelled ? (
            <div className="p-4 bg-yellow-500/10 border border-yellow-500/20 rounded-lg text-yellow-400 text-sm text-center">
              <div className="flex items-center justify-center gap-2 mb-2">
                <ClockIcon className="w-5 h-5" />
                <span className="font-medium">Cancelación programada</span>
              </div>
              <div className="text-gray-300 text-xs space-y-1">
                <p>Tu suscripción permanecerá activa hasta:</p>
                <p className="font-medium text-yellow-400">
                  {subscription?.end_date ? 
                    new Date(subscription.end_date).toLocaleDateString('es-ES', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    }) : 
                    'Fecha no disponible'
                  }
                </p>
                <p className="mt-2 text-gray-400">
                  Mantendrás acceso a todos los beneficios hasta esa fecha
                </p>
              </div>
            </div>
          ) : (
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => setShowCancelDialog(true)}
              disabled={loading}
              className="w-full py-3 px-4 bg-red-600 hover:bg-red-700 text-white rounded-lg transition-all font-medium disabled:opacity-50"
            >
              {loading ? 'Procesando...' : 'Cancelar Suscripción'}
            </motion.button>
          )}
        </div>
      )}
      
      {!isActive && (
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={() => onSubscribe(plan.id)}
          disabled={loading || (subscription?.cancelled && plan.id === subscription?.plan_id)}
          className={`
            w-full py-3 px-4 rounded-lg transition-all font-medium
            bg-gradient-to-r ${plan?.color || 'from-blue-500 to-blue-600'} text-white hover:shadow-lg
            disabled:opacity-50 disabled:cursor-not-allowed
          `}
        >
          {loading ? 'Procesando...' : 'Seleccionar Plan'}
        </motion.button>
      )}
    </motion.div>
  );
};
const PasswordForm = ({ onSubmit, loading }) => (
  <motion.form
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.3 }}
    onSubmit={onSubmit}
    className="max-w-md space-y-6"
  >
    <div>
      <label className="block text-gray-400 mb-2">Contraseña Actual</label>
      <input
        type="password"
        name="currentPassword"
        required
        className="w-full bg-gray-700/50 text-white rounded-lg p-3 border border-gray-600 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all outline-none"
      />
    </div>

    <div>
      <label className="block text-gray-400 mb-2">Nueva Contraseña</label>
      <input
        type="password"
        name="newPassword"
        required
        className="w-full bg-gray-700/50 text-white rounded-lg p-3 border border-gray-600 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all outline-none"
      />
    </div>

    <div>
      <label className="block text-gray-400 mb-2">Confirmar Nueva Contraseña</label>
      <input
        type="password"
        name="confirmPassword"
        required
        className="w-full bg-gray-700/50 text-white rounded-lg p-3 border border-gray-600 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all outline-none"
      />
    </div>

    <motion.button
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      type="submit"
      disabled={loading}
      className="w-full py-3 px-6 bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white font-medium rounded-lg transition-all disabled:opacity-50"
    >
      {loading ? 'Procesando...' : 'Actualizar Contraseña'}
    </motion.button>
  </motion.form>
);

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [personalityTest, setPersonalityTest] = useState(null);
  const [activeTab, setActiveTab] = useState('general');
  const [processingSubscription, setProcessingSubscription] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);

  const showError = (message) => {
    setError(message);
    setTimeout(() => setError(null), 5000);
  };

  const fetchUserProfile = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user-profile`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      
      setUser(response.data);
      if (response.data.personality_analysis_ai) {
        setPersonalityTest(response.data.personality_analysis_ai);
      }
    } catch (err) {
      console.error('Error fetching profile:', err);
      showError('Error al cargar el perfil');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);
// En la función que maneja la respuesta del backend
const handleUserProfile = (response) => {
  setUser({
    ...response.data,
    subscription_end_date: response.data.subscription_end_date // Asegúrate de que esto llegue
  });
};
  const handleSubscribe = async (planId) => {
    try {
      setProcessingSubscription(true);
      const token = localStorage.getItem('token');
      
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/subscriptions/create`,
        { plan_id: planId },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.init_point) {
        localStorage.setItem('pending_subscription', JSON.stringify({
          plan_id: planId,
          timestamp: Date.now()
        }));
        
        window.location.href = response.data.init_point;
      } else {
        throw new Error('No se recibió el punto de inicio del pago');
      }
    } catch (err) {
      showError(err.response?.data?.error || 'Error al procesar la suscripción');
    } finally {
      setProcessingSubscription(false);
    }
  };

  const handleCancelSubscription = async (reason) => {
    try {
      setProcessingSubscription(true);
      setError(null);
      const token = localStorage.getItem('token');
      
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/subscriptions/cancel`,
        { reason },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
  
      // Actualizar el estado local con la información de cancelación
      setUser(prevUser => ({
        ...prevUser,
        subscription_status: 'cancelled',
        subscription_cancelled: true,
        subscription_end_date: response.data.details.active_until
      }));
  
      setShowCancelDialog(false);
      
      // Mostrar mensaje de éxito con la fecha formateada
      const endDate = new Date(response.data.details.active_until).toLocaleDateString('es-ES', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
      
      alert(`Tu suscripción ha sido marcada para cancelación.\n\nTu servicio permanecerá activo hasta el ${endDate}.\n\nSeguirás teniendo acceso a todas las funcionalidades hasta esa fecha.`);
      
      // Actualizar el perfil completo para asegurar la sincronización
      await fetchUserProfile();
  
    } catch (err) {
      console.error('Error en la cancelación:', err);
      showError(err.response?.data?.error || 'Error al cancelar la suscripción. Por favor, intenta nuevamente o contacta a soporte.');
    } finally {
      setProcessingSubscription(false);
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setProcessingSubscription(true);

    try {
      const formData = new FormData(e.target);
      const newPassword = formData.get('newPassword');
      const confirmPassword = formData.get('confirmPassword');

      if (newPassword !== confirmPassword) {
        throw new Error('Las contraseñas no coinciden');
      }

      const token = localStorage.getItem('token');
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/update-password`,
        {
          current_password: formData.get('currentPassword'),
          new_password: newPassword
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      alert('Contraseña actualizada exitosamente');
      e.target.reset();
    } catch (err) {
      showError(err.response?.data?.error || err.message);
    } finally {
      setProcessingSubscription(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900">
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-7xl mx-auto bg-gray-800/50 backdrop-blur-sm rounded-xl p-6">
          {/* Header */}
          <div className="mb-8">
            <h1 className="text-4xl font-bold text-white mb-2">Mi Perfil</h1>
            <p className="text-gray-400">Gestiona tu cuenta y preferencias</p>
          </div>

          {/* Error message */}
          {error && (
            <div className="p-4 mb-6 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400">
              {error}
            </div>
          )}

          {/* Tabs */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
            <TabButton
              active={activeTab === 'general'}
              icon={<UserIcon className="w-5 h-5" />}
              label="General"
              onClick={() => setActiveTab('general')}
            />
            <TabButton
              active={activeTab === 'security'}
              icon={<LockClosedIcon className="w-5 h-5" />}
              label="Seguridad"
              onClick={() => setActiveTab('security')}
            />
            <TabButton
              active={activeTab === 'subscription'}
              icon={<CreditCardIcon className="w-5 h-5" />}
              label="Suscripción"
              onClick={() => setActiveTab('subscription')}
            />
            <TabButton
              active={activeTab === 'personality'}
              icon={<BriefcaseIcon className="w-5 h-5" />}
              label="Test de Personalidad"
              onClick={() => setActiveTab('personality')}
            />
          </div>

          {/* Content */}
          <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-6">
            {/* General Tab */}
            {activeTab === 'general' && (
              <div className="space-y-6">
                <div className="bg-gradient-to-r from-gray-800/50 to-gray-700/50 p-6 rounded-xl border border-gray-600/20">
                  <div className="flex items-center gap-6">
                    <div className="w-20 h-20 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center">
                      <UserIcon className="w-10 h-10 text-white" />
                    </div>
                    <div>
                      <h3 className="text-2xl font-bold text-white mb-1">
                        {user?.first_name} {user?.last_name}
                      </h3>
                      <p className="text-gray-300">{user?.email}</p>
                      {user?.active_subscription && (
                        <div className="flex items-center gap-2 mt-2">
                          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-500/20 text-blue-400">
                            <CheckIcon className="w-4 h-4 mr-1" />
                            Plan {user.active_subscription}
                          </span>
                          {user?.subscription_cancelled && (
                            <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-yellow-500/20 text-yellow-400">
                              <ClockIcon className="w-4 h-4 mr-1" />
                              Cancelada
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Security Tab */}
            {activeTab === 'security' && (
              <div>
                <h2 className="text-2xl font-bold text-white mb-6">Seguridad de la Cuenta</h2>
                <div className="max-w-2xl">
                  <div className="bg-gray-800/30 rounded-xl p-6 mb-8 border border-gray-700/50">
                    <div className="flex items-start gap-4">
                      <div className="p-3 rounded-lg bg-blue-500/20">
                        <ShieldCheckIcon className="w-6 h-6 text-blue-400" />
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold text-white mb-2">
                          Actualiza tu contraseña
                        </h3>
                        <p className="text-gray-400">
                          Para mantener tu cuenta segura, te recomendamos cambiar tu contraseña regularmente.
                        </p>
                      </div>
                    </div>
                  </div>
                  <PasswordForm
                    onSubmit={handlePasswordChange}
                    loading={processingSubscription}
                  />
                </div>
              </div>
            )}

           {/* Subscription Tab */}
{activeTab === 'subscription' && (
  <div>
    <h2 className="text-2xl font-bold text-white mb-6">Plan de Suscripción</h2>
    
    {user?.active_subscription && (
      <div className="bg-gray-800/30 rounded-xl p-6 mb-8 border border-gray-700/50">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <div className="p-3 rounded-lg bg-blue-500/20">
              <CreditCardIcon className="w-6 h-6 text-blue-400" />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-white">Plan Actual</h3>
              <p className="text-gray-400">
                {SUBSCRIPTION_PLANS[user.active_subscription]?.name}
              </p>
            </div>
          </div>
        </div>
        
        {user?.subscription_end_date && (
          <div className="mt-4 pt-4 border-t border-gray-700">
            <div className="flex items-center gap-2 text-gray-400">
              <ClockIcon className="w-5 h-5" />
              <span>
                {user?.subscription_cancelled ? 
                  'Tu acceso terminará el ' : 
                  'Activo hasta: '
                }
                {new Date(user.subscription_end_date).toLocaleDateString('es-ES', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </span>
            </div>
          </div>
        )}
      </div>
    )}

    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {Object.entries(SUBSCRIPTION_PLANS).map(([planId, plan]) => (
        <PlanCard
          key={planId}
          plan={plan}
          isActive={user?.active_subscription === planId}
          onSubscribe={handleSubscribe}
          loading={processingSubscription}
          subscription={{
            cancelled: user?.subscription_cancelled,
            end_date: user?.subscription_end_date,
            plan_id: user?.active_subscription
          }}
          setShowCancelDialog={setShowCancelDialog}
        />
      ))}
    </div>
  </div>
)}

            {/* Personality Test Tab */}
            {activeTab === 'personality' && (
              <div>
                <h2 className="text-2xl font-bold text-white mb-6">Test de Personalidad</h2>
                
                {personalityTest ? (
                  <div className="space-y-6">
                    <div className="bg-gray-800/30 rounded-xl p-6 border border-gray-700/50">
                      <div className="prose prose-invert max-w-none">
                        {personalityTest.analysis.split('\n').map((line, index) => {
                          if (line.startsWith('TIPO MBTI')) {
                            return (
                              <h3 key={index} className="text-xl font-bold text-blue-400 mt-6 first:mt-0">
                                {line}
                              </h3>
                            );
                          }
                          if (line.startsWith('FORTALEZAS') || line.startsWith('ÁREAS') || line.startsWith('RECOMENDACIONES')) {
                            return (
                              <h4 key={index} className="text-lg font-semibold text-purple-400 mt-6">
                                {line}
                              </h4>
                            );
                          }
                          if (line.trim().startsWith('-')) {
                            return (
                              <div key={index} className="flex items-start gap-2 ml-4 text-gray-300">
                                <span className="text-purple-400 mt-1.5">•</span>
                                <span>{line.substring(1).trim()}</span>
                              </div>
                            );
                          }
                          return <p key={index} className="text-gray-300">{line}</p>;
                        })}
                      </div>
                    </div>
                    
                    <div className="bg-blue-900/20 border border-blue-500/20 rounded-lg p-4">
                      <div className="flex items-start gap-3">
                        <SparklesIcon className="w-5 h-5 text-blue-400 flex-shrink-0 mt-0.5" />
                        <p className="text-blue-400 text-sm">
                          Este análisis está basado en tus respuestas al test de personalidad.
                          Úsalo como guía para desarrollar tu marca personal y mejorar tu comunicación.
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="bg-gray-800/30 rounded-xl p-6 border border-gray-700/50">
                    <p className="text-gray-300">
                      {user?.active_subscription === 'foco'
                        ? 'Necesitas el Plan Potencia o superior para acceder al test de personalidad.'
                        : 'Aún no has realizado el test de personalidad.'}
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Diálogo de cancelación */}
      <CancelSubscriptionDialog
  isOpen={showCancelDialog}
  onClose={() => setShowCancelDialog(false)}
  onConfirm={handleCancelSubscription}
  loading={processingSubscription}
  subscriptionEndDate={user.subscription_end_date} // Asegúrate de que esto tenga un valor válido
/>
    </div>
  );
};

export default UserProfile;
