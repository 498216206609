import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { XMarkIcon, ClockIcon } from '@heroicons/react/24/outline';

const CancelSubscriptionDialog = ({ isOpen, onClose, onConfirm, loading, subscriptionEndDate }) => {
  const [reason, setReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  
  const reasons = [
    'El precio es muy alto',
    'No uso el servicio lo suficiente',
    'No cumple mis expectativas',
    'Encontré una mejor alternativa',
    'Problemas técnicos',
    'Otro'
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    onConfirm(reason === 'Otro' ? otherReason : reason);
  };

  // Función para formatear la fecha
  const formatDate = (dateString) => {
    try {
      if (!dateString) return 'Fecha no disponible';
      
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return 'Fecha no disponible';

      return date.toLocaleDateString('es-ES', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (error) {
      console.error('Error formateando fecha:', error);
      return 'Fecha no disponible';
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className="bg-gray-800 rounded-xl max-w-md w-full p-6 shadow-xl relative"
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors"
        >
          <XMarkIcon className="w-6 h-6" />
        </button>

        <h3 className="text-xl font-bold text-white mb-2">Cancelar Suscripción</h3>
        
        <div className="mb-6 p-4 bg-blue-900/20 border border-blue-500/20 rounded-lg">
          <div className="flex items-start gap-3">
            <ClockIcon className="w-5 h-5 text-blue-400 flex-shrink-0 mt-0.5" />
            <div className="text-sm">
              <p className="text-blue-400 font-medium mb-1">
                Tu suscripción seguirá activa hasta el final del período
              </p>
              <p className="text-gray-400">
                Mantendrás acceso completo a todos los beneficios hasta: {' '}
                <span className="text-blue-400 font-medium">
                  {formatDate(subscriptionEndDate)}
                </span>
              </p>
            </div>
          </div>
        </div>

        <p className="text-gray-300 mb-6">
          Lamentamos que quieras cancelar tu suscripción. ¿Podrías ayudarnos a mejorar indicándonos el motivo?
        </p>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-400 mb-2">Motivo de cancelación</label>
            <select
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              required
              className="w-full bg-gray-700/50 text-white rounded-lg p-3 border border-gray-600 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all outline-none"
            >
              <option value="">Selecciona un motivo</option>
              {reasons.map((r) => (
                <option key={r} value={r}>{r}</option>
              ))}
            </select>
          </div>

          {reason === 'Otro' && (
            <div>
              <label className="block text-gray-400 mb-2">Especifica el motivo</label>
              <textarea
                value={otherReason}
                onChange={(e) => setOtherReason(e.target.value)}
                className="w-full bg-gray-700/50 text-white rounded-lg p-3 border border-gray-600 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all outline-none"
                rows="3"
                placeholder="Cuéntanos más..."
                required
              />
            </div>
          )}

          <div className="flex justify-end gap-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              disabled={loading}
              className="px-4 py-2 text-gray-300 hover:text-white transition-colors disabled:opacity-50"
            >
              Cancelar
            </button>
            <button
              type="submit"
              disabled={loading || !reason || (reason === 'Otro' && !otherReason)}
              className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-lg transition-all disabled:opacity-50"
            >
              {loading ? 'Procesando...' : 'Confirmar Cancelación'}
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default CancelSubscriptionDialog;