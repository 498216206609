import React, { useState, useRef } from 'react';

const LoginForm = ({ onLogin, onSwitch, onForgotPassword }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const handleKeyDown = (e, nextRef) => {
    if (e.key === ' ') {
      e.preventDefault();
      if (nextRef.current) {
        nextRef.current.focus();
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username_or_email: email.toLowerCase(), password }),
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        if (response.status === 401) {
          setError('Correo de usuario o contraseña inválidos');
        } else {
          setError('Error al iniciar sesión. Por favor, intenta de nuevo.');
        }
        return;
      }
      
      // Si la respuesta es exitosa, llamar a onLogin con los datos
      onLogin(data);
    } catch (error) {
      setError('Error de conexión. Por favor, intenta de nuevo más tarde.');
    }
  };

  return (
    <div className="min-h-screen flex">
      {/* Left side */}
      <div 
        className="hidden md:flex md:w-1/2 relative items-center justify-center"
        style={{
          background: 'linear-gradient(169deg, #000000 0%, #0A403C 100%)'
        }}
      >
        <div className="w-[520px] px-8 py-12">
          {/* Logo */}
          <img 
            src="https://vos.personalmarka.com/personal-marka.png" 
            alt="Personal Marka" 
            className="h-12 mb-12"
          />
          
          <div className="space-y-12">
            <div>
              <h1 className="text-5xl font-bold text-white leading-tight">
                Bienvenido a tu<br />
                marca personal
              </h1>
              
              <p className="text-[#76A695] text-base mt-2">
                Continúa gestionando tu presencia<br />
                profesional y alcanzando nuevas metas.
              </p>
            </div>

            <div className="space-y-8">
              <div>
                <h3 className="text-white text-xl font-medium">
                  Mayor visibilidad
                </h3>
                <p className="text-[#76A695] text-base mt-0.5">
                  Destaca en tu sector y atrae mejores<br />
                  oportunidades profesionales
                </p>
              </div>
              
              <div>
                <h3 className="text-white text-xl font-medium">
                  Red profesional
                </h3>
                <p className="text-[#76A695] text-base mt-0.5">
                  Construye conexiones valiosas con otros<br />
                  profesionales del sector
                </p>
              </div>
              
              <div>
                <h3 className="text-white text-xl font-medium">
                  Gestión segura
                </h3>
                <p className="text-[#76A695] text-base mt-0.5">
                  Administra tu presencia digital de forma<br />
                  segura y profesional
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Right side - Form */}
      <div className="w-full md:w-1/2 flex items-center justify-center bg-white">
        <div className="w-[420px] px-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-2">
            Iniciar Sesión
          </h2>
          <p className="text-gray-700 text-base mb-8">
            Accede a tu cuenta para continuar con tu desarrollo profesional
          </p>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-base font-medium text-gray-800 mb-1">
                Correo:
              </label>
              <input
                type="email"
                id="email"
                ref={emailRef}
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                onKeyDown={(e) => handleKeyDown(e, passwordRef)}
                className="w-full px-3 py-2 border border-gray-300 rounded text-gray-900"
                required
              />
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-800 mb-1">
                Contraseña:
              </label>
              <input
                type="password"
                id="password"
                ref={passwordRef}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded text-gray-900"
                required
              />
            </div>

            {error && (
              <div className="mb-4 p-3 bg-red-50 border border-red-200 text-red-600 rounded text-sm">
                {error}
              </div>
            )}

            <button
              type="submit"
              className="w-full py-2.5 bg-black text-white rounded hover:bg-gray-900 transition-colors"
            >
              Iniciar sesión
            </button>
          </form>

          <div className="mt-6 space-y-2 text-center">
            <button 
              onClick={onForgotPassword}
              className="text-sm text-gray-800 hover:text-gray-900"
            >
              ¿Olvidaste tu contraseña?
            </button>
            <div>
              <button 
                onClick={onSwitch} 
                className="text-sm text-gray-800 hover:text-black"
              >
                Registrarse
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;