import React, { useState, useRef, useEffect } from 'react';

const Notification = ({ message, type = 'success', onClose }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, 5000);
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className={`fixed top-4 left-1/2 transform -translate-x-1/2 z-50 
      ${type === 'success' ? 'bg-green-500' : 'bg-red-500'} 
      text-white px-6 py-4 rounded-lg shadow-lg transition-all duration-300 ease-in-out`}>
      {message}
    </div>
  );
};

const RegisterForm = ({ onRegister, onSwitch }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [notification, setNotification] = useState(null);

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const handleKeyDown = (e, nextRef) => {
    if (e.key === ' ') {
      e.preventDefault();
      if (nextRef.current) {
        nextRef.current.focus();
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setNotification({
        message: "Las contraseñas no coinciden",
        type: 'error'
      });
      return;
    }
    
    try {
      onRegister(
        formData.email.toLowerCase(),
        formData.password,
        formData.firstName,
        formData.lastName
      );
      
      // Reset form and show success notification
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: ''
      });
      
      setNotification({
        message: "¡Registro exitoso! Bienvenido a Personal Marka.",
        type: 'success'
      });
    } catch (error) {
      // Handle any registration errors
      setNotification({
        message: "Error en el registro: " + (error.message || "Intentelo de nuevo"),
        type: 'error'
      });
    }
  };

  return (
    <div className="min-h-screen flex relative">
      {/* Notification */}
      {notification && (
        <Notification 
          message={notification.message} 
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}

      {/* Left side */}
      <div 
        className="hidden md:flex md:w-1/2 relative items-center justify-center"
        style={{
          background: 'linear-gradient(169deg, #000000 0%, #0A403C 100%)'
        }}
      >
        <div className="w-[620px] px-8 py-8">
          {/* Logo */}
          <img 
            src="https://vos.personalmarka.com/personal-marka.png" 
            alt="Personal Marka" 
            className="h-12 mb-6"
          />
          
          <div>
            <h1 className="text-5xl font-bold text-white mb-4">
              Comienza tu viaje<br />
              hacia una marca<br />
              personal única
            </h1>
            
            <p className="text-[#76A695] text-lg mb-12">
              Únete a la comunidad de profesionales que están<br />
              transformando su presencia digital.
            </p>

            <div className="grid grid-cols-2 gap-16">
              {/* Left column */}
              <div>
                <h3 className="text-white text-2xl font-medium mb-6">
                  Comienza tu camino al<br />
                  éxito profesional
                </h3>
                
                <div className="space-y-6">
                  <div>
                    <div className="flex items-center gap-3">
                      <div className="w-2 h-2 bg-cyan-400 rounded-full" />
                      <p className="text-white text-lg font-medium">Regístrate</p>
                    </div>
                    <p className="text-[#76A695] text-base ml-5 mt-2">
                      Crea tu cuenta y accede a todas<br />
                      las herramientas
                    </p>
                  </div>
                  
                  <div>
                    <div className="flex items-center gap-3">
                      <div className="w-2 h-2 bg-orange-400 rounded-full" />
                      <p className="text-white text-lg font-medium">Define tu marca</p>
                    </div>
                    <p className="text-[#76A695] text-base ml-5 mt-2">
                      Establece tu identidad<br />
                      profesional única
                    </p>
                  </div>
                  
                  <div>
                    <div className="flex items-center gap-3">
                      <div className="w-2 h-2 bg-yellow-400 rounded-full" />
                      <p className="text-white text-lg font-medium">Conéctate</p>
                    </div>
                    <p className="text-[#76A695] text-base ml-5 mt-2">
                      Construye tu red profesional<br />
                      estratégica
                    </p>
                  </div>
                  
                  <div>
                    <div className="flex items-center gap-3">
                      <div className="w-2 h-2 bg-purple-400 rounded-full" />
                      <p className="text-white text-lg font-medium">Crece</p>
                    </div>
                    <p className="text-[#76A695] text-base ml-5 mt-2">
                      Potencia tu presencia y alcanza<br />
                      tus metas
                    </p>
                  </div>
                </div>
              </div>

              {/* Right column */}
              <div>
                <h3 className="text-white text-2xl font-medium mb-6">
                  Beneficios de unirse a<br />
                  Personal Marka
                </h3>
                
                <div className="space-y-8">
                  <div>
                    <p className="text-white text-lg font-medium mb-2">
                      Presencia Profesional
                    </p>
                    <p className="text-[#76A695] text-base">
                      Desarrolla una imagen profesional sólida y coherente en todas las plataformas digitales
                    </p>
                  </div>
                  
                  <div>
                    <p className="text-white text-lg font-medium mb-2">
                      Gestión Simplificada
                    </p>
                    <p className="text-[#76A695] text-base">
                      Herramientas intuitivas para gestionar tu presencia digital de manera efectiva
                    </p>
                  </div>
                  
                  <div>
                    <p className="text-white text-lg font-medium mb-2">
                      Crecimiento Continuo
                    </p>
                    <p className="text-[#76A695] text-base">
                      Acceso a recursos y estrategias para el desarrollo profesional continuo
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Right side - Form */}
      <div className="w-full md:w-1/2 flex items-center justify-center bg-white">
        <div className="w-[480px] px-8">
          <h2 className="text-4xl font-bold text-gray-900 mb-3">
            Crear cuenta
          </h2>
          <p className="text-gray-600 text-lg mb-6">
            Completa tus datos para comenzar a construir tu marca personal
          </p>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label htmlFor="firstName" className="block text-base font-medium text-gray-800 mb-1">
                  Nombre:
                </label>
                <input
                  type="text"
                  id="firstName"
                  ref={firstNameRef}
                  value={formData.firstName}
                  onChange={(e) => setFormData({...formData, firstName: e.target.value})}
                  onKeyDown={(e) => handleKeyDown(e, lastNameRef)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md text-gray-900 text-base"
                  required
                />
              </div>
              <div>
                <label htmlFor="lastName" className="block text-base font-medium text-gray-800 mb-1">
                  Apellido:
                </label>
                <input
                  type="text"
                  id="lastName"
                  ref={lastNameRef}
                  value={formData.lastName}
                  onChange={(e) => setFormData({...formData, lastName: e.target.value})}
                  onKeyDown={(e) => handleKeyDown(e, emailRef)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md text-gray-900 text-base"
                  required
                />
              </div>
            </div>

            <div>
              <label htmlFor="email" className="block text-base font-medium text-gray-800 mb-1">
                Correo:
              </label>
              <input
                type="email"
                id="email"
                ref={emailRef}
                value={formData.email}
                onChange={(e) => setFormData({...formData, email: e.target.value.toLowerCase()})}
                onKeyDown={(e) => handleKeyDown(e, passwordRef)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md text-gray-900 text-base"
                required
              />
            </div>

            <div>
              <label htmlFor="password" className="block text-base font-medium text-gray-800 mb-1">
                Contraseña:
              </label>
              <input
                type="password"
                id="password"
                ref={passwordRef}
                value={formData.password}
                onChange={(e) => setFormData({...formData, password: e.target.value})}
                onKeyDown={(e) => handleKeyDown(e, confirmPasswordRef)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md text-gray-900 text-base"
                required
              />
            </div>

            <div>
              <label htmlFor="confirmPassword" className="block text-base font-medium text-gray-800 mb-1">
                Confirmar Contraseña:
              </label>
              <input
                type="password"
                id="confirmPassword"
                ref={confirmPasswordRef}
                value={formData.confirmPassword}
                onChange={(e) => setFormData({...formData, confirmPassword: e.target.value})}
                className="w-full px-4 py-2 border border-gray-300 rounded-md text-gray-900 text-base"
                required
              />
            </div>

            <button
              type="submit"
              className="w-full py-3 mt-2 bg-black text-white text-lg font-medium rounded-md hover:bg-gray-900 transition-colors"
            >
              Crear cuenta
            </button>
          </form>

          <div className="mt-6 text-center">
            <p className="text-base text-gray-600">
              ¿Ya tienes cuenta?{' '}
              <button 
                onClick={onSwitch}
                className="text-black hover:text-gray-900 font-medium"
              >
                Inicia sesión
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;