import React, { useState } from 'react';

const PasswordResetRequestForm = ({ onRequestReset }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onRequestReset(email.toLowerCase());
  };

  return (
    <div className="min-h-screen flex">
      {/* Left side */}
      <div 
        className="hidden md:flex md:w-1/2 relative items-center justify-center"
        style={{
          background: 'linear-gradient(169deg, #000000 0%, #0A403C 100%)'
        }}
      >
        <div className="w-[420px] px-8 py-12">
          {/* Logo */}
          <img 
            src="https://vos.personalmarka.com/personal-marka.png" 
            alt="Personal Marka" 
            className="h-12 mb-12"
          />
          
          <div className="space-y-12">
            <div>
              <h1 className="text-5xl font-bold text-white leading-tight">
                Restablecer<br />
                contraseña
              </h1>
              
              <p className="text-[#76A695] text-base mt-2">
                Te ayudaremos a recuperar el acceso<br />
                a tu cuenta de forma segura.
              </p>
            </div>

            <div className="space-y-8">
              <div>
                <h3 className="text-white text-xl font-medium">
                  Proceso seguro
                </h3>
                <p className="text-[#76A695] text-base mt-0.5">
                  El proceso de restablecimiento es<br />
                  completamente seguro y encriptado
                </p>
              </div>
              
              <div>
                <h3 className="text-white text-xl font-medium">
                  Verificación en dos pasos
                </h3>
                <p className="text-[#76A695] text-base mt-0.5">
                  Usamos verificación por correo para<br />
                  garantizar tu identidad
                </p>
              </div>
              
              <div>
                <h3 className="text-white text-xl font-medium">
                  Soporte disponible
                </h3>
                <p className="text-[#76A695] text-base mt-0.5">
                  Nuestro equipo está disponible si<br />
                  necesitas ayuda adicional
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Right side - Form */}
      <div className="w-full md:w-1/2 flex items-center justify-center bg-white">
        <div className="w-[420px] px-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-2">
            Restablecer contraseña
          </h2>
          <p className="text-gray-700 text-base mb-8">
            Ingresa tu correo electrónico y te enviaremos un código para restablecer tu contraseña.
          </p>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-base font-medium text-gray-800 mb-1">
                Correo:
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded text-gray-900"
                placeholder="tucorreo@ejemplo.com"
              />
            </div>

            <button
              type="submit"
              className="w-full py-2.5 bg-black text-white rounded hover:bg-gray-900 transition-colors"
            >
              Enviar código
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetRequestForm;